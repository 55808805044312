import styled from 'styled-components';

export const StyledObject = styled.object`
  width: 100%;
  height: 70vh;
`;

export const StyledDivDialog = styled.div`
  padding: 10px;
`;
